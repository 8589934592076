import _ from "underscore";
import ApiService from "Services/apiService";
import UtilService from "Services/utilService";

const SiteService = {
    requestThing: async (thingId) => {
        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        // console.log(requestOptions);
        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response)
                return response; // A thing object
            })
            .catch((e) => {
                console.error(e);
            });
    },

    list: async (company, setRows) => {
        let requestOptions = {
            url: `/v2/sites?company=${company}&page=0&pageSize=100`,
        };

        // console.log(requestOptions);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log(response);
                setRows(response.content);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    create: async (name, address, city, postalCode, latitude, longitude, company) => {
        let requestOptions = {
            url: `/api/sites?company=${company}`,
            data: {
                name: name,
                address: address,
                city: city,
                postalCode: postalCode,
                latitude: latitude,
                longitude: longitude,
            },
        };

        // console.log(requestOptions);
        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    update: async (id, name, address, city, postalCode, latitude, longitude) => {
        let requestOptions = {
            url: `/api/sites`,
            data: {
                id: id,
                name: name,
                address: address,
                city: city,
                postalCode: postalCode,
                latitude: latitude,
                longitude: longitude,
            },
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    assign: async (thingId, siteId) => {
        let requestOptions = {
            url: `/v2/sites/assign`,
            data: {
                thingId: thingId,
                siteId: siteId,
            },
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    delete: async (siteId) => {
        let requestOptions = {
            url: `/v2/sites/${siteId}`,
        };

        // console.log(requestOptions);
        return ApiService.deleteData(requestOptions)
            .then((response) => {
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default SiteService;
