import { useState } from "react";
import Highcharts from "highcharts/highstock";
import moment from "moment-timezone";
import UtilService from "Services/utilService";
import dayjs from "dayjs";
import { ChartLangOption } from "./ChartLangOption";

export function ChartGeneralOptions(type, series, t, i18n) {
    const [groupEnable, setGroupEnable] = useState(true);

    let first = series && series.length > 0 ? series[0] : null;
    let second = series && series.length > 1 ? series[1] : null;

    const langSettings = ChartLangOption(i18n);

    return {
        chart: {
            type: type,
            // width: '100vw'
        },
        title: {
            text: null,
        },
        xAxis: {
            type: "datetime",
            labels: {
                overflow: "justify",
            },
            events: {
                afterSetExtremes: function (e) {
                    var minDate = dayjs(e.min);
                    var maxDate = dayjs(e.max);
                    var differenceInDays = maxDate.diff(minDate, "days");

                    if (differenceInDays < 2) {
                        setGroupEnable(false);
                    } else {
                        setGroupEnable(true);
                    }
                },
            },
        },
        yAxis: [
            {
                labels: {
                    format: `{value} ${first?.attribute?.unit || ""}`,
                    style: {
                        color: Highcharts.getOptions().colors[4],
                    },
                },
                title: {
                    text: first?.attribute?.name === "Battery" ? t("attr.battery") : first?.attribute?.name || "",
                    style: {
                        color: Highcharts.getOptions().colors[4],
                    },
                },
            },
            {
                labels: {
                    format: `{value} ${second?.attribute?.unit || ""}`,
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: second?.attribute?.name || "",
                    style: {
                        color: Highcharts.getOptions().colors[1],
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
            split: false,
            formatter: function () {
                let formattedDate = UtilService.formatMDHms(this.x);
                let result = formattedDate;

                this.points.forEach((point) => {
                    let unit = point.series.yAxis.userOptions.labels.format.split(" ")[1];
                    result += `<br/><b>${point.series.name}: ${UtilService.truncate2Digits(point.y)} ${unit}</b>`;
                });

                return result;
            },
        },
        credits: {
            enabled: false,
        },
        time: {
            timezone: moment.tz.guess(),
            useUTC: false,
        },
        series: series.map((s, index) => ({
            name: s.attribute ? s.attribute.name : `Series ${index + 1}`,
            data: s.chartData,
            zIndex: 1,
            marker: {
                fillColor: "white",
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[index],
            },
            showInLegend: false,
            dataGrouping: {
                groupPixelWidth: 5,
                enabled: groupEnable,
            },
            yAxis: index,
        })),
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbol: "download",
                },
            },
        },
        rangeSelector: {
            buttons: [
                {
                    type: "day",
                    count: 1,
                    text: "1d raw",
                    events: {
                        click: function () {
                            setGroupEnable(false);
                        },
                    },
                },
                {
                    type: "week",
                    count: 1,
                    text: "1w",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "month",
                    count: 1,
                    text: "1m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "1y",
                    count: 1,
                    text: "12m",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
                {
                    type: "all",
                    text: "All",
                    events: {
                        click: function () {
                            setGroupEnable(true);
                        },
                    },
                },
            ],
            selected: 5,
        },
        lang: langSettings,
    };
}
