import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import UserService from "Services/userService";
import CloudUserService from "./CloudUserService";
import CreateUserDialog from "./CreateUserDialog";
import { UserListColumn } from "./UserListColumn";
import moment from "moment";
import ThingList from "./ThingList";
import Stack from "@mui/material/Stack";
import { CustomButton } from "../../../components/ui/CustomButton";
import CustomConfirmDialog from "../../../components/ui/CustomConfirmDialog";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function UserList() {
    const { t, i18n } = useTranslation();
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState([{ field: "email", sort: "asc" }]);
    const [open, setOpen] = useState(false);

    const [company, setCompany] = useState("");
    const [reload, setReload] = useState();
    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedUserEmail, setSelectedUserEmail] = useState();
    const [selectedUserRole, setSelectedUserRole] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [selectedIsSubCompany, setSelectedIsSubCompany] = useState();
    const [selectionModel, setSelectionModel] = useState([]);

    const [openConfirm, setOpenConfirm] = useState(false);
    const [message, setMessage] = useState("");
    const [hideNo, setHideNo] = useState(false);

    const handleEnabled = (e) => {
        CloudUserService.changeEnabled(e.target.name, e.target.checked).then(() => {
            setReload(moment());
        });
    };

    const columns = UserListColumn(handleEnabled, t);

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            // console.log(user);
            if (user.authorities.indexOf("SUPER_ADMIN") != -1) {
                CloudUserService.requestCloudUserV2(paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort, undefined);
            } else {
                setCompany(user.company);
                CloudUserService.requestCloudUserV2(paginationModel.page, paginationModel.pageSize, setRows, setRowCount, sort, user.company);
            }
        });
    }, [paginationModel.page, paginationModel.pageSize, reload, sort]);

    const handleRowClick = (params) => {
        // console.log(params);
        setSelectedUserId(params.row.id);
        setSelectedUserEmail(params.row.email);
        setSelectedUserRole(params.row.role);
        setSelectedCompany(params.row.company);
        setSelectionModel([params.row.id]);
        setSelectedIsSubCompany(params.row.isSubCompany);
    };

    const handleSelectionModel = (param) => {};

    const handleAddUser = () => {
        setOpen(true);
    };

    const handleRemoveUser = () => {
        // remove the user
        // console.log(selectedUserId);
        CloudUserService.removeUser(selectedUserId).then(() => setReload(moment()));
    };

    const handleRemoveConfirm = () => {
        if (!selectedUserId) {
            return;
        }

        if (selectedUserEmail) {
            setMessage(`${t("de.user_del_msg_1")} '${selectedUserEmail}'?`);
            setHideNo(false);
        } else {
            setMessage(t("de.user_del_msg_2"));
            setHideNo(true);
        }
        setOpenConfirm(true);
    };

    const handleCallback = () => {
        setReload(moment().unix());
    };

    const handleSortModelChange = (params) => {
        // console.log(params);
        setSort(params);
    };

    return (
        <Box sx={{ height: "100vh", p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid item xs={5} sx={{ display: "flex", flexDirection: "row", overflow: "auto" }}>
                    <Stack direction={"column"} spacing={2} sx={{ pt: 8, width: "100%" }}>
                        <Stack direction="row" spacing={2}>
                            <CustomButton name={t("btn.add")} callback={handleAddUser} />
                            <CustomButton name={t("btn.remove")} callback={handleRemoveConfirm} />
                        </Stack>
                        <DataGridPro
                            rows={rows}
                            rowCount={rowCount}
                            columns={columns}
                            disableColumnFilter
                            paginationModel={paginationModel}
                            pagination={true}
                            paginationMode="server"
                            sortingMode="server"
                            pageSizeOptions={[10, 20]}
                            onPaginationModelChange={setPaginationModel}
                            // onRowSelectionModelChange={handleSelectionModel}
                            density="compact"
                            checkboxSelection={false}
                            disableMultipleSelection={true}
                            disableRowSelectionOnClick={false}
                            // disableSelectionOnClick
                            // style={{ height: '100%' }}
                            // headerHeight={0}
                            hideFooterSelectedRowCount
                            onRowClick={handleRowClick}
                            onSortModelChange={handleSortModelChange}
                            selectionModel={selectionModel}
                            sx={{
                                "& .MuiTablePagination-selectLabel": {
                                    marginTop: 2,
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    marginTop: 2,
                                },
                                "& .MuiDataGrid-row.Mui-selected": {
                                    backgroundColor: "#B7D9FB",
                                },
                            }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        username: false,
                                        isSubCompany: false,
                                    },
                                },
                            }}
                            localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                            rowHeight={60}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={7} sx={{ display: "flex", flexDirection: "row" }}>
                    <ThingList
                        selectedUserId={selectedUserId}
                        selectedCompany={selectedCompany}
                        selectedUserRole={selectedUserRole}
                        isCompanyPage={false}
                        selectedIsSubCompany={selectedIsSubCompany}
                    />
                </Grid>
            </Grid>

            <CreateUserDialog open={open} setOpen={setOpen} callback={handleCallback} company={company} />

            <CustomConfirmDialog
                open={openConfirm}
                setOpen={setOpenConfirm}
                title={t("de.user_del_title")}
                message={message}
                hideNo={hideNo}
                callback={handleRemoveUser}
            />
        </Box>
    );
}
