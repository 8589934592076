import ApiService from "Services/apiService";
import _ from "underscore";
import UtilService from "Services/utilService";
import UserService from "Services/userService";
import moment from "moment";

const NotificationService = {
    requestLastNotifications: async (setLastSms, setLastEmail, appId) => {
        let requestOptions = {
            url: `/api/notification/last/${appId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                response.forEach((ele) => {
                    if (ele.notificationType == "PHONE") {
                        setLastSms(UtilService.formatMDYHm(ele.creationTimeStamp));
                    } else if (ele.notificationType == "EMAIL") {
                        setLastEmail(UtilService.formatMDYHm(ele.creationTimeStamp));
                    }
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestMonthlyTotalNotifications: async (setMonthlyTotal, appId) => {
        let requestOptions = {
            url: `/api/notification/monthly_total/${appId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                let str = [];
                response.forEach((ele) => {
                    if (ele.notificationType == "PHONE") {
                        str.push(`${ele.total} SMS`);
                    } else if (ele.notificationType == "EMAIL") {
                        str.push(`${ele.total} Email`);
                    }
                });

                if (!_.isEmpty(str)) {
                    setMonthlyTotal(str.join(",") + " (" + UtilService.formatMonth(moment()) + ")");
                }
            })
            .catch((e) => {
                console.error(e);
            });
    },

    requestNotifications: async (
        setIsWarning,
        setIsWarningEmail,
        setIsWarningPhone,
        setIsInfo,
        setIsInfoEmail,
        setIsInfoPhone,
        setIsCritical,
        setIsCriticalEmail,
        setIsCriticalPhone,
        setEmail,
        setPhone,
        appId
    ) => {
        const user = await UserService.getAuthenticatedUser();

        let requestOptions = {
            url: `/api/notification?filter=company.name:eq_${user.company},system_notification.application_id:eq_${appId}`,
        };

        return ApiService.getData(requestOptions)
            .then((response) => {
                response.data.forEach((row) => {
                    switch (row.severity) {
                        case "WARNING":
                            setIsWarning(row.enableSeverity);
                            setIsWarningEmail(row.enableEmail);
                            setIsWarningPhone(row.enablePhone);
                            break;
                        case "INFO":
                            setIsInfo(row.enableSeverity);
                            setIsInfoEmail(row.enableEmail);
                            setIsInfoPhone(row.enablePhone);
                            break;
                        case "CRITICAL":
                            setIsCritical(row.enableSeverity);
                            setIsCriticalEmail(row.enableEmail);
                            setIsCriticalPhone(row.enablePhone);
                            break;
                        default:
                            setIsCritical(false);
                            setIsCriticalEmail(false);
                            setIsCriticalPhone(false);
                            break;
                    }

                    // all rows have same email and phone
                    setEmail(row.email);
                    setPhone(row.phone);
                });
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateNotification: async (
        isWarning,
        isWarningEmail,
        isWarningPhone,
        isInfo,
        isInfoEmail,
        isInfoPhone,
        isCritical,
        isCriticalEmail,
        setIsCriticalPhone,
        email,
        phone,
        handleClose,
        appId
    ) => {
        let requestOptions = {
            url: "/api/notification",
            data: {
                applicationId: appId,
                locale: "en",
                severitySettings: [
                    {
                        name: "WARNING",
                        enableSeverity: isWarning,
                        enableEmail: isWarningEmail,
                        enablePhone: isWarningPhone,
                    },
                    {
                        name: "INFO",
                        enableSeverity: isInfo,
                        enableEmail: isInfoEmail,
                        enablePhone: isInfoPhone,
                    },
                    {
                        name: "CRITICAL",
                        enableSeverity: isCritical,
                        enableEmail: isCriticalEmail,
                        enablePhone: setIsCriticalPhone,
                    },
                ],
                email: email,
                phone: phone,
                description: "",
            },
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log(response);
                handleClose();
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default NotificationService;
