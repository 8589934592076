import { useEffect, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import style from "./style.module.css";
import IconButton from "@mui/material/IconButton";
import MainMenu from "./MainMenu";
import Setting from "./Setting";
import UserMenu from "./UserMenu";
import AppContext from "../AppContext";
import UserService from "Services/userService";
import NotificationSetting from "Routes/smartAgriculture/notification/NotificationSetting";
import { AppId } from "../../components/applications/ApplicationData";
import TimeoutSetup from "./TimeoutSetup";

const baseTitle = "Sensor Apps";
// const user = useContext(AuthenticatedUser)

function isOnHome(path) {
    if (path.indexOf("/device_explorer/share") != -1 || path.indexOf("/device_explorer/catalog") != -1) {
        return false;
    }

    const homePaths = [
        `${process.env.PUBLIC_URL}`,
        `${process.env.PUBLIC_URL}/smart_agriculture`,
        `${process.env.PUBLIC_URL}/sens_dc`,
        `${process.env.PUBLIC_URL}/agriculture_water`,
        `${process.env.PUBLIC_URL}/device_explorer/device_explorer`,
        `${process.env.PUBLIC_URL}/device_explorer/measurement/favorite`,
        `${process.env.PUBLIC_URL}/device_explorer/gateways/gatewaylist`,
        `${process.env.PUBLIC_URL}/device_explorer/users/grouplist`,
        `${process.env.PUBLIC_URL}/device_explorer/users/userlist`,
    ];

    return homePaths.some((homePath) => path.includes(homePath));
}

function isSmartAgriculture(path) {
    return path.indexOf("smart_agriculture") !== -1;
}

function isSensDc(path) {
    return path.indexOf("sens_dc") !== -1;
}

function isDeviceExplorer(path) {
    return path.indexOf("device_explorer") !== -1;
}

function isTemperatureMornitoring(path) {
    return path.indexOf("temperature_monitoring") !== -1;
}

// (example)
// ${process.env.PUBLIC_URL}/device_explorer/catalog/manufacturerList 	-> length 5 -> goto the app's home : ${process.env.PUBLIC_URL}/device_explorer/device_explorer
// ${process.env.PUBLIC_URL}/device_explorer/device_explorer				-> lenght 4 -> goto the list page  : ${process.env.PUBLIC_URL}/
function isSubPages(history) {
    let paths = history.location.pathname.split("/");
    if (paths.length > 4) {
        return true;
    }
}

export default function Header() {
    let history = useHistory();
    let location = useLocation();

    const ctx = useContext(AppContext);
    const title = ctx.title;
    const dynamicTitle = title ? title : baseTitle;
    const [enableGoHome, setEnableGoHome] = useState(true);
    const [isPth, setIsPth] = useState(false);

    const handleClick = (event) => {
        history.push(`${process.env.PUBLIC_URL}/`);
    };

    useEffect(() => {
        UserService.getAuthenticatedUser().then((user) => {
            if (user.authorities == "APPLICATION_DEVICE_EXPLORER") {
                // this user can only see the device explorer
                setEnableGoHome(false);
            }
            if (user.company == "PTH") {
                setIsPth(true);
            }
        });
    }, [history]);

    useEffect(() => {
        // console.log("URL changed:", location.pathname);
        // Perform any actions you need based on the URL change
    }, [location.pathname]);

    return (
        <header className={style.header}>
            <MainMenu></MainMenu>
            {!isOnHome(history.location.pathname) && (
                <IconButton
                    aria-label="go-back"
                    onClick={() => {
                        if (enableGoHome) {
                            history.goBack();
                        } else {
                            if (isSubPages(history)) {
                                history.goBack();
                            } else {
                                history.push(`${process.env.PUBLIC_URL}/`);
                            }
                        }
                    }}
                    size="large"
                >
                    <i className="fa fa-arrow-left" style={{ color: "white" }}></i>
                </IconButton>
            )}
            <h1 style={{ margin: "0px", paddingLeft: "10px" }}>{dynamicTitle}</h1>
            <nav>
                {dynamicTitle !== baseTitle && enableGoHome && (
                    <IconButton aria-label="delete" onClick={handleClick} size="large">
                        <i className="fa fa-th" style={{ color: "white" }}></i>
                    </IconButton>
                )}
                {isSmartAgriculture(history.location.pathname) && isPth && <NotificationSetting appId={AppId.SMART_AGRICULTURE}></NotificationSetting>}
                {isSensDc(history.location.pathname) && <NotificationSetting appId={AppId.SENS_DC}></NotificationSetting>}
                {isTemperatureMornitoring(history.location.pathname) && <NotificationSetting appId={AppId.TEMPERATURE_MONITORING}></NotificationSetting>}
                {isDeviceExplorer(history.location.pathname) && <TimeoutSetup />}
                <Setting></Setting>
                <UserMenu></UserMenu>
            </nav>
        </header>
    );
}
