import { useState, useEffect } from "react";
import _ from "underscore";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { LicenseInfo } from "@mui/x-license-pro";
import { v4 as uuidv4 } from "uuid";
import { Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import CustomStyle from "../../deviceExplorer/share/CustomStyle";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import SiteDialogue from "../site/SiteDialogue";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function CustomerDetail({ entity, selectedThingId, setReload, thing }) {
    const [index, setIndex] = useState(0);
    const [zone, setZone] = useState("");
    const [tenant, setTenant] = useState("");
    const [value, setValue] = useState(dayjs());
    const [isMaster, setIsMaster] = useState(true);
    const [counter, setCounter] = useState(10);

    const [openSite, setOpenSite] = useState(false);

    useEffect(() => {
        // console.log("useEffect in ElectricDetail");
        // console.log(entity);

        setZone(entity.floor + " " + entity.name);
        if (thing && thing.site) {
            setZone(thing.site.name);
        }

    }, [isMaster, thing]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleMaster = (e) => {
        // console.log("handleMaster....");
        // console.log(e.target.checked);
        setIsMaster(e.target.checked);
    };

    const handleCounter = (e) => {
        // console.log("handleCounter....");
        // console.log(e);
        setCounter(e.target.value);
    };

    return (
        <TableContainer component={Paper} elevation={3} sx={{ overflow: "hidden" }}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableBody>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left" width={"100%"}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <TextField
                                    id="txtIndex"
                                    variant="outlined"
                                    // defaultValue={"Salle 1 et Salle 2"}
                                    value={index}
                                    onChange={(e) => setIndex(e.target.value)}
                                    size="small"
                                    type="number"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    label="Start index"
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Date"
                                        inputFormat="MM/dd/yyyy"
                                        value={value}
                                        onChange={handleChange}
                                        // renderInput={(params) => <TextField {...params} size="small" />}
                                        slotProps={{ textField: { variant: "outlined", size: "small" } }}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left" width={"100%"}>
                            <Box sx={{ display: "flex" }}>
                                <TextField
                                    id="txtZone"
                                    variant="outlined"
                                    // defaultValue={"Salle 1 et Salle 2"}
                                    value={zone}
                                    onChange={(e) => setZone(e.target.value)}
                                    fullWidth
                                    size="small"
                                    label="Site"
                                />
                                {entity.id == 1 && (
                                    <IconButton aria-label="delete" size="large" variant="contained" onClick={() => setOpenSite(true)}>
                                        <MoreHorizIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                            </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left" width={"100%"}>
                            <TextField
                                id="txtTenant"
                                variant="outlined"
                                // defaultValue={"Salle 1 et Salle 2"}
                                value={tenant}
                                onChange={(e) => setTenant(e.target.value)}
                                fullWidth
                                size="small"
                                label="Tenant"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow key={uuidv4()}>
                        <TableCell align="left" colSpan={2}>
                            <Stack spacing={2} direction={"row"} sx={{ display: "flex", alignItems: "center" }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Switch checked={isMaster} onChange={(e) => handleMaster(e)} inputProps={{ "aria-label": "controlled" }} />}
                                    label="Master counter"
                                    labelPlacement="start"
                                />

                                {!isMaster && (
                                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                                        <FormControl sx={{ width: 200 }}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={counter}
                                                // label="Counter"
                                                onChange={(e) => handleCounter(e)}
                                                size="small"
                                                sx={CustomStyle.RemoveLabel}
                                            >
                                                <MenuItem value={10}>Counter 1</MenuItem>
                                                <MenuItem value={20}>Counter 2</MenuItem>
                                                <MenuItem value={30}>Counter 3</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                            </Stack>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <SiteDialogue
                open={openSite}
                setOpen={setOpenSite}
                selectedThingId={selectedThingId}
                setReloadParent={() => {
                    // console.log("setReloadParent......")
                    setReload(moment().unix());
                }}
            />
        </TableContainer>
    );
}
