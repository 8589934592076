import UtilService from "Services/utilService";
import ApiService from "Services/apiService";
import _ from "underscore";

const GatewayService = {
    requestGateways: async (page, pageSize, setRows, sort, appId, isAdmin, company, setRowCount) => {
        let requestOptions = {
            url: `/v2/gateways`,
            params: {
                page: page,
                pageSize: pageSize,
                sort: sort.map((s) => `${s.field}:${s.sort}`).join(","),
                company: company,
            },
        };

        // if (!isAdmin) {
        //     requestOptions.url += `&filter=company:eq_${company}`;
        // }

        requestOptions.url = encodeURI(requestOptions.url);

        return ApiService.getData(requestOptions)
            .then((response) => {
                // console.log("requestGateways...Response");
                // console.log(response);

                let data = [];

                response.content.forEach((ele) => {
                    // console.log(ele);

                    let obj = {
                        id: ele.id,
                        name: ele.name,
                        connectionStatus: ele.connectionStatus == "Connected" ? "ACTIVE" : "INACTIVE",
                        lastUpdated: UtilService.shortFormateDate(ele.lastUpdated * 1000),
                        type: ele.type,
                        gatewayEui: ele.gatewayEui,
                        rfRegion: ele.rfRegion,
                        "company.name": ele.company.name,
                    };

                    data.push(obj);
                });

                setRowCount(response.totalElements);
                setRows(data);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateGatewayStatus: async (id) => {
        let requestOptions = {
            url: `/api/gateways/${id}`,
        };

        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("updateGatewayStatus...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    updateGatewayName: async (id, name) => {
        let requestOptions = {
            url: `/api/gateways/${id}/${encodeURI(name)}`,
        };

        // console.log(requestOptions);
        return ApiService.putData(requestOptions)
            .then((response) => {
                // console.log("updateGatewayName...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },

    createGateway: async (devEui, company, rfRegion, description) => {
        let requestOptions = {
            url: `/api/gateways/create`,
            data: {
                devEui: devEui,
                company: company,
                rfRegion: rfRegion,
                description: description,
            },
        };

        return ApiService.postData(requestOptions)
            .then((response) => {
                // console.log("createGateway...Response");
                // console.log(response);
            })
            .catch((e) => {
                console.error(e);
            });
    },
};

export default GatewayService;
