import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Typography from '@mui/material/Typography';
import CropHistory from './history/CropHistory';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import CropService from '../crop/CropService';
import UserConfirm from './UserConfirm';
import CustomStyle from './CustomStyle';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

export default function NewCropDialog(props) {
    // const [open, setOpen] = useState(false);

    const [name, setName] = useState();
    const [startDate, setStartDate] = useState(null);
    const [reloadState, setReloadState] = useState();
    const [activeCrop, setActiveCrop] = useState();
    const [openUserConfrim, setOpenUserConfrim] = useState(false);
    const [message, setMessage] = useState();

    const [error, setError] = useState({
        name: false,
        startDate: false
    });

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleClose = () => {
        // setOpen(false);
        props.close();
    };

    const handleName = (e) => {
        setName(e.target.value);
    }

    const handleStart = () => {

        let errorVal = {
            name: (name == undefined || name == '' ? true : false),
            startDate: (startDate == undefined || startDate == '' || startDate < new Date(2000, 1, 1) ? true : false)
        }
        setError(errorVal);

        if (errorVal.name || errorVal.startDate) {
            return;
        }

        if (name != undefined && name != '' && startDate != undefined && startDate != null) {
            CropService.requestActiveCrop(setActiveCrop)
                .then((activeCrop) => {
                    if (activeCrop == undefined) {
                        CropService.insertCrop(name, startDate, setReloadHistory);
                    } else {
                        setMessage(`Starting a new crop will finish the active crop '${activeCrop.name}'`);
                        setOpenUserConfrim(true);
                    }

                });
            // CropService.insertCrop(name, startDate, setReloadHistory);
        }
    }

    const setReloadHistory = () => {
        setReloadState(moment.unix());
    }

    const closeUserConfirm = (yesNo) => {
        setOpenUserConfrim(false);
        if (yesNo) {
            CropService.insertCrop(name, startDate, setReloadHistory);
        }
    }

    useEffect(() => {
        // setOpen(props.open);
        // do nothing
    }, []);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Add a new crop"}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid Grid item xs={2} sm={2}>
                                            <Typography align="left">
                                                Name
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4}>
                                            <TextField
                                                required
                                                name="txtName"
                                                fullWidth
                                                margin="dense"
                                                size="small"
                                                onChange={(e) => handleName(e)}
                                                error={error.name ? true : false}
                                                sx={CustomStyle.RemoveLabel}
                                            />
                                        </Grid>
                                        <Grid Grid item xs={6} sm={6}>
                                            <Stack spacing={2} direction="row">
                                                <Button variant="contained" onClick={() => handleStart()}>Start</Button>
                                            </Stack>
                                        </Grid>
                                        <Grid Grid item xs={2} sm={2}>
                                            <Typography align="left">
                                                Starting Date
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4}  >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                                <DatePicker
                                                    value={startDate}
                                                    onChange={(newValue) => {
                                                        setStartDate(newValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params} size='small' margin='none' fullWidth error={error.startDate ? true : false} sx={CustomStyle.RemoveLabel} />}
                                                    minDate={new Date(2000, 1, 1)}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>&nbsp;</Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <Typography align='left'>
                                        Crop growth history
                                    </Typography>
                                </Item>
                                <Item>
                                    <CropHistory reloadState={reloadState} style={{ width: '100%', height: '100%' }}></CropHistory>
                                </Item>
                            </Grid>
                        </Grid>
                        <UserConfirm open={openUserConfrim} close={closeUserConfirm} message={message}></UserConfirm>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
