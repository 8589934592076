import React, { useState, useEffect } from "react";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import _ from "underscore";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/ui/BootstrapDialog";
import DialogContent from "@mui/material/DialogContent";
import { CustomButton } from "../../../components/ui/CustomButton";
import GatewayService from "./GatewayService";
import moment from "moment";
import UserService from "Services/userService";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import validator from "validator";

export default function CreateGatewayDialog({ open, setOpen, setReload }) {
    const { t, i18n } = useTranslation();

    const [devEui, setDevEui] = useState("");
    const [rfRegion, setRfRegion] = useState("");
    const [description, setDescription] = useState("");
    const [company, setCompany] = useState("");

    const [loading, setLoading] = useState(false);
    const [errDevEui, setErrDevEui] = useState(false);
    const [errRfRegion, setErrRfRegion] = useState(false);

    const handleClose = () => {
        setDevEui("");

        setOpen(false);
        setLoading(false);
        setErrDevEui(false);
    };

    const handleSubmit = () => {
        // console.log("handleSubmit...") 
        if (validator.isEmpty(devEui)) {
            setErrDevEui(true);
            return;
        }

        const isValidDevEUI = (devEui) => /^[0-9A-Fa-f]{16}$/.test(devEui);
        // console.log("isValidDevEUI=" + isValidDevEUI(devEui));

        if (!isValidDevEUI(devEui)) {
            setErrDevEui(true);
            return;
        }
        setErrDevEui(false);


        if (validator.isEmpty(rfRegion)) {
            setErrRfRegion(true);
            return;
        }
        setErrRfRegion(false);

        setLoading(true);

        GatewayService.createGateway(devEui, company, rfRegion, description).then(() => {
            setReload(moment().unix());
            setLoading(false);
            handleClose();
        });
    };

    useEffect(() => {
        // console.log("useEffect...in CreateuserDialog");
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u)
            // setUsername(u.cognitoUserName);
            // setUser(u);
            setCompany(u.company);
        });
    }, []);

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth="sm" fullWidth>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.gw_new")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={{ pt: 2, pl: 5, pr: 5, pb: 2 }}>
                        <Stack spacing={2}>
                            <TextField
                                variant="standard"
                                label="DEVEUI"
                                type="email"
                                fullWidth
                                required
                                disabled={loading}
                                value={devEui}
                                onChange={(e) => setDevEui(e.target.value)}
                                helperText={t("de.gw_enter")}
                                error={errDevEui}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="lbl-rf-region">RF Region</InputLabel>
                                <Select
                                    labelId="sel-rf-region"
                                    id="demo-simple-select"
                                    value={rfRegion}
                                    label="RF Region"
                                    onChange={(e) => setRfRegion(e.target.value)}
                                    variant="standard"
                                    disabled={loading}
                                    error={errRfRegion}
                                >
                                    <MenuItem value={"EU868"}>EU868</MenuItem>
                                    <MenuItem value={"US915"}>US915</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                variant="standard"
                                label="Description"
                                fullWidth
                                disabled={loading}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows={2}
                                multiline
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <CustomButton name={t("btn.add")} callback={handleSubmit} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
