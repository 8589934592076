import { useState, useEffect } from "react";
import Button from "@mui/material/Button";

export function CustomButton({ startIcon, name, callback, toggled }) {
    const [customStyle, setCustomStyle] = useState();

    useEffect(() => {
        if (name) {
            switch (name.toUpperCase()) {
                case "SAVE":
                    setCustomStyle({ backgroundColor: "#90B962", color: "#FFFFFF", textTransform: "none" });
                    break;
                case "DELETE":
                    setCustomStyle({ backgroundColor: "#D24352", color: "#FFFFFF", textTransform: "none" });
                    break;
                default:
                    if (toggled) {
                        setCustomStyle({ backgroundColor: "#1976D2", color: "#FFFFFF", textTransform: "none" });
                    } else {
                        setCustomStyle({ backgroundColor: "#5694B5", color: "#FFFFFF", textTransform: "none" });
                    }
                    break;
            }
        }
    }, [name, toggled]);

    return (
        <>
            {startIcon && (
                <Button
                    variant="contained"
                    startIcon={startIcon}
                    style={customStyle}
                    onClick={(e) => callback(e)}
                >
                    {name}
                </Button>
            )}
            {!startIcon && (
                <Button variant="contained" style={customStyle} onClick={(e) => callback(e)}>
                    {name}
                </Button>
            )}
        </>
    );
}
