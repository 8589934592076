import { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SuperviotConstants from "../SuperviotConstants";
import TooltipSlider from "./TooltipSlider";
import "rc-slider/assets/index.css";

export default function SliderNitrate({ name, marks, defaultValue, min = 0, max, step = 1, attributeId, callback }) {
    const trackColors = [
        { backgroundColor: SuperviotConstants.BLUE },
        { backgroundColor: SuperviotConstants.GREEN },
        { backgroundColor: SuperviotConstants.YELLOW },
        { backgroundColor: SuperviotConstants.ORANGE },
        { backgroundColor: SuperviotConstants.RED },
    ];

    useEffect(() => {
        // console.log(step);
    }, [marks, defaultValue, min, max, step]);

    const handleChange = (value) => {
        // console.log(value);
        callback(attributeId, trackColors, value, false);
    };

    return (
        <Box sx={{ mb: 5 }}>
            <Typography id="track-false-range-slider" gutterBottom>
                {name}
            </Typography>
            <TooltipSlider
                range
                count={5}
                marks={marks}
                defaultValue={defaultValue}
                pushable
                trackStyle={trackColors}
                handleStyle={[
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                    { backgroundColor: SuperviotConstants.DEFAULT },
                ]}
                railStyle={{ backgroundColor: "black" }}
                onChangeComplete={handleChange}
                min={min}
                max={max}
                step={step}
            />
        </Box>
    );
}
