import ApiService from "Services/apiService";

const IslandService = {
    requestThing: async (thingId) => {
        // console.log("requestThing...");
        // console.log(thingId);

        let requestOptions = {
            url: `/api/things/${thingId}?detailed=true`,
        };

        return ApiService.getData(requestOptions).then((r) => {
            // console.log(r);

            if (r.lastMeasurements && r.lastMeasurements.measurements) {
                let measure = r.lastMeasurements.measurements;
                let units = r.lastMeasurements.units;
                let timestamp = r.lastMeasurementsTimestamps.measurements;

                const combinedData = Object.keys(measure).map((key) => ({
                    name: key,
                    measurement: measure[key],
                    unit: units[key] ? units[key] : "",
                    timestamp: timestamp[key],
                }));

                let worstColor = r.customFields
                    ?.filter((c) => c.name === "WORST_COLOR")
                    .map((c) => c.value)
                    .find((c) => c);

                if (worstColor) {
                    combinedData["worstColor"] = worstColor;
                }

                // combinedData will now be an array of objects combining measure, unit, and timestamp
                // console.log(combinedData);

                return combinedData;
            }
        });
    },
};

export default IslandService;
