import _ from "underscore";
import Box from "@mui/material/Box";
import BoilerPlateTable from "./BoilerPlateTable";

export default function BoilerPlateContainer() {
    return (
        <Box sx={{ pt: 20 }}>
            <BoilerPlateTable />
        </Box>
    );
}
