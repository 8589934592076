import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import _ from 'underscore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ConfirmDialog from './ConfirmDialog';
import ShareService from './ShareService';
import validator from 'validator';
import CustomStyle from './CustomStyle';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export default function SetupIotESRI(props) {

    let history = useHistory();
    let errors = {
        url: false,
        headers: false,
        timezone: false
    }
    const setActiveStep = props.setActiveStep;

    const [companyName, setCompanyName] = useState();

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [enable, setEnable] = useState(true);
    const [description, setDescription] = useState();
    const [url, setUrl] = useState();
    const [headers, setHeaders] = useState();
    const [timezone, setTimezone] = useState("");

    const [allTimezone, setAllTimezone] = useState([]);
    const [open, setOpen] = useState(false);
    const loading = open && allTimezone.length === 0;

    useEffect(() => {
        setActiveStep(1);

        let active = true;

        // if (!loading) {
        //     return undefined;
        // }

        ShareService.requestTimezone(setAllTimezone);

        ShareService.getCompany(setCompanyName, setTimezone)
            .then((companyName) => {
                ShareService.getFlowTargetEsri(companyName, setFlowId, setForwardTargetId,
                    setEnable, setDescription, setUrl, setHeaders, setTimezone)
            });

    }, [loading]);

    const handleSave = () => {
        if (!isValidate()) {
            return;
        }

        ShareService.getCompany(setCompanyName, setTimezone)
            .then((companyName) => {
                ShareService.getFlowTargetEsri(companyName, setFlowId, setForwardTargetId,
                    setEnable, setDescription, setUrl, setHeaders, setTimezone)
                    .then(() => {
                        if (!forwardTargetId) {
                            // create a flow, enabled=false
                            ShareService.createFlow(companyName, "Share-ESRI")
                                .then((flowId) => {
                                    // Insert MQTT integration, enabled=false
                                    ShareService.insertForwardEsri(enable, description, url, headers, timezone)
                                        .then((savedForwardTargetId) => {
                                            // Insert Action
                                            setForwardTargetId(savedForwardTargetId);
                                            ShareService.createAction(flowId, savedForwardTargetId)
                                                .then(() => props.goCompletePage());
                                        });

                                });
                        } else {
                            // Update configuration
                            ShareService.updateForwardEsri(forwardTargetId, enable, description, url, headers, timezone)
                                .then(() => props.goCompletePage());
                        }
                    });
            });
    }

    const isValidate = () => {
        if (url == undefined || validator.isEmpty(url)) {
            errors.url = true;
            return false;
        }

        return true;
    }

    const handleValue = (e) => {
        const value = e.target.value;
        if (e.target.name == 'txtDescription') {
            setDescription(value);
        } else if (e.target.name == 'txtUrl') {
            setUrl(value);
        } else if (e.target.name == 'txtHeaders') {
            setHeaders(value);
        }
    }

    const handleTimezone = (e) => {
        setTimezone(e.target.textContent);
    }

    const closeDialog = (answer) => {
        if (!answer) {
            props.goFirstPage();
        }
    }

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {props.on &&
                    <ConfirmDialog callback={closeDialog}></ConfirmDialog>
                }

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: '#9CC96B' }} onClick={() => handleSave()}>Save</Button>
                            {/* <Button variant="contained" style={{ backgroundColor: '#D55421' }} onClick={() => handleCancel()}>Cancel</Button> */}
                        </Stack>
                    </Grid>
                </Grid>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    URL
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtUrl"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={url}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.url}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Headers
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtHeaders"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={headers}
                                    multiline
                                    rows={4}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.headers}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>
                                    Timezone
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    // isOptionEqualToValue={(option, value) => option.title === value.title}
                                    getOptionLabel={(option) => option.title}
                                    // value={timezone}
                                    value={{ title: timezone }}
                                    options={allTimezone}
                                    loading={loading}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                            sx={CustomStyle.RemoveLabel}
                                        />
                                    )}
                                    fullWidth
                                    onChange={(e) => handleTimezone(e)}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    )
}