import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomStyle from "./CustomStyle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DesktopTimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import validator from "validator";
import moment from "moment";
import _ from "underscore";
import ThresholdServiceV2 from "./ThresholdServiceV2";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function ThresholdDialogV2Input(props) {
    const { t, i18n } = useTranslation();
    const { thingId, attributeId } = props;

    const [errorLow, setErrorLow] = useState(false);
    const [errorHigh, setErrorHigh] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const [timeStart, setTimeStart] = useState(dayjs("2020-01-01T09:00:00"));
    const [timeEnd, setTimeEnd] = useState(dayjs("2020-01-01T17:00:00"));
    const [isAllTime, setIsAllTime] = useState(false);
    const [txtLow, setTxtLow] = useState("");
    const [txtHigh, setTxtHigh] = useState("");
    const [disableTime, setDisableTime] = useState(false);

    const handleTimeChange = (type, e) => {
        if (type == "start") {
            setTimeStart(e);
        } else {
            setTimeEnd(e);
        }
    };

    const handleAll = (e) => {
        if (e.target.checked) {
            setDisableTime(true);
        } else {
            setDisableTime(false);
        }
        setIsAllTime(e.target.checked);
    };

    const handleThresholdChange = (e) => {
        const value = e.target.value;
        if (e.target.name == "txtLow") {
            if (validator.isNumeric(value)) {
                setErrorMessage("");
                setErrorLow(false);
            } else {
                setErrorMessage("This value is not valid");
                setErrorLow(true);
            }
            setTxtLow(value);
        } else {
            if (validator.isNumeric(value)) {
                setErrorMessage("");
                setErrorHigh(false);
            } else {
                setErrorMessage("This value is not valid");
                setErrorHigh(true);
            }
            setTxtHigh(value);
        }
    };

    const handleClose = () => {
        initError();
        setTxtLow("");
        setTxtHigh("");
        props.close();
    };

    const handleSave = () => {
        if (_.isUndefined(txtLow) || validator.isEmpty(txtLow) || !validator.isNumeric(txtLow)) {
            setErrorLow(true);
            setErrorMessage("Low value is not valid");
            return;
        }
        if (_.isUndefined(txtHigh) || validator.isEmpty(txtHigh) || !validator.isNumeric(txtHigh)) {
            setErrorHigh(true);
            setErrorMessage("High value is not valid");
            return;
        }

        initError();

        let strStart = timeStart.format("HHmm");
        let strEnd = timeEnd.format("HHmm");

        if (strStart >= strEnd) {
            setErrorMessage("Time value is not valid");
            return;
        }

        if (txtLow >= txtHigh) {
            setErrorLow(true);
            setErrorHigh(true);
            setErrorMessage("Threshold value  is not valid");
            return;
        }

        setErrorMessage("");

        ThresholdServiceV2.insertThreshold(thingId, attributeId, isAllTime, moment(timeStart).unix(), moment(timeEnd).unix(), txtLow, txtHigh)
            .then(() => {
                callbackSave();
            })
            .catch((e) => {
                callbackError();
            });
    };

    const initError = () => {
        setErrorLow(false);
        setErrorHigh(false);
        setErrorMessage("");
    };

    const callbackSave = () => {
        setDisableTime(false);
        setIsAllTime(false);
        setTxtLow("");
        setTxtHigh("");
        props.close();
    };

    const callbackError = (message) => {
        setErrorMessage(message);
    };

    useEffect(() => {
        // do nothting
    }, [errorMessage]);

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} disableEnforceFocus={true}>
                <DialogTitle> {t("btn.threshold")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={1} sm={1}>
                            <Typography>Start</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopTimePicker disabled={disableTime} value={timeStart} onChange={(e) => handleTimeChange("start", e)} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Typography>End</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopTimePicker disabled={disableTime} value={timeEnd} onChange={(e) => handleTimeChange("end", e)} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={(e) => handleAll(e)} />} label="All time" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Typography>Low</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <TextField
                                required
                                name="txtLow"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={txtLow}
                                onChange={(e) => handleThresholdChange(e)}
                                error={errorLow ? true : false}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Typography>High</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <TextField
                                required
                                name="txtHigh"
                                fullWidth
                                margin="dense"
                                size="small"
                                value={txtHigh}
                                onChange={(e) => handleThresholdChange(e)}
                                error={errorHigh ? true : false}
                                sx={CustomStyle.RemoveLabel}
                            />
                        </Grid>
                        {errorMessage && (
                            <Grid item xs={12} sm={12}>
                                <Alert severity="error">{errorMessage}</Alert>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
