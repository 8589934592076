import { useEffect, useState } from "react";
import _ from "underscore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ConfirmDialog from "./ConfirmDialog";
import ShareService from "./ShareService";
import CustomStyle from "./CustomStyle";
import validator from "validator";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { grey, blue } from "@mui/material/colors";
import ButtonGroup from "@mui/material/ButtonGroup";
import UploadButtons from "./https_cert/UploadButtons";
import Alert from "@mui/material/Alert";

export default function SetupHTTP(props) {
    let defaltError = {
        host: false,
        url: false,
        headers: false,
        method: false,
        grouped: false,
        message: "",
        https: false,
    };
    const [errors, setError] = useState(defaltError);
    const setActiveStep = props.setActiveStep;
    const [alignment, setAlignment] = useState();

    const [txtMessageFormat, setTxtMessageFormat] = useState();
    const [companyName, setCompanyName] = useState();

    const [flowId, setFlowId] = useState();
    const [forwardTargetId, setForwardTargetId] = useState();

    const [host, setHost] = useState("");
    const [url, setUrl] = useState();
    const [headers, setHeaders] = useState();
    const [method, setMethod] = useState("POST");
    const [grouped, setGrouped] = useState(false);

    const [chkMessage, setChkMessage] = useState(false);
    const [chkThing, setChkThing] = useState(false);
    const [chkProduct, setChkProduct] = useState(false);
    const [chkGateway, setChkGateway] = useState(false);

    const [connection, setConnection] = useState("http");
    const [files, setFiles] = useState([3]);
    const [filesOnCloud, setFilesOnCloud] = useState([]);

    useEffect(() => {
        setActiveStep(1);

        ShareService.getCompany(setCompanyName).then((companyName) => {
            ShareService.getFlowTargetHttp(
                companyName,
                setFlowId,
                setForwardTargetId,
                setUrl,
                setHeaders,
                setMethod,
                setGrouped,
                setChkMessage,
                setChkThing,
                setChkProduct,
                setChkGateway,
                setHost,
                setFilesOnCloud
            ).then((r) => {
                if (r === "https") {
                    setConnection("https");
                    setFiles(filesOnCloud);
                    // console.log(filesOnCloud);
                }
            });
        });

        ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, chkGateway]);
    }, []);

    const handleSave = () => {
        if (!formValidate()) {
            return;
        }

        // console.log(files);
        // if (connection === "https") {
        //     if (!files[0] || !files[1] || !files[2]) {
        //         // console.log("All files are must be chosen");
        //         setError((e) => {
        //             e.https = true;
        //             return e;
        //         });
        //         return;
        //     }
        //     setError((e) => {
        //         e.https = false;
        //         return e;
        //     });
        // }

        ShareService.getCompany(setCompanyName).then((companyName) => {         
            if (!forwardTargetId) {
                // create a flow, enabled=false
                ShareService.createFlow(companyName, "Share-HTTP").then((flowId) => {
                    // Insert HTTP integration, enabled=false

                    if (connection === "http") {
                        ShareService.insertForwardHttp(url, headers, method, grouped, [chkMessage, chkThing, chkProduct, chkGateway], undefined).then(
                            (savedForwardTargetId) => {
                                // Insert Action
                                setForwardTargetId(savedForwardTargetId);
                                ShareService.createAction(flowId, savedForwardTargetId).then(() => props.goCompletePage());
                            }
                        );
                    } else {
                        ShareService.insertForwardHttp(url, headers, method, grouped, [chkMessage, chkThing, chkProduct, chkGateway], files).then(
                            (savedForwardTargetId) => {
                                // Insert Action
                                setForwardTargetId(savedForwardTargetId);
                                ShareService.createAction(flowId, savedForwardTargetId).then(() => props.goCompletePage());
                            }
                        );
                    }
                });
            } else {
                // Update configuration
                if (connection === "http") {
                    ShareService.updateForwardHttp(
                        forwardTargetId,
                        url,
                        headers,
                        method,
                        grouped,
                        [chkMessage, chkThing, chkProduct, chkGateway],
                        undefined
                    ).then(() => props.goCompletePage());
                } else {
                    ShareService.updateForwardHttp(forwardTargetId, url, headers, method, grouped, [chkMessage, chkThing, chkProduct, chkGateway], files).then(
                        () => props.goCompletePage()
                    );
                }
            } 
        });
    };

    const formValidate = () => {
        if (
            validator.isURL(url, {
                protocols: ["http", "https"],
                require_protocol: true,
                require_valid_protocol: true,
                require_host: true,
                allow_protocol_relative_urls: true,
                require_tld: false,
            })
        ) {
            defaltError.url = false;
            setError(defaltError);
        } else {
            defaltError.url = true;
            setError(defaltError);
            return false;
        }

        if (headers != undefined && headers != "") {
            const arrHeader = headers.split("\n");
            let errorHeader = false;

            for (var i = 0; i < arrHeader.length; i++) {
                const re = /\w+=\w+/;
                if (re.test(arrHeader[i])) {
                    errorHeader = false;
                } else {
                    errorHeader = true;
                    break;
                }
            }

            if (errorHeader) {
                defaltError.headers = true;
                setError(defaltError);
                return false;
            } else {
                defaltError.headers = false;
                setError(defaltError);
            }
        }

        return true;
    };

    const handleMsgFormat = (e) => {
        const value = e.target.checked;
        if (e.target.name === "chkMessage") {
            setChkMessage(value);
            ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [value, chkThing, chkProduct, chkGateway]);
        } else if (e.target.name === "chkThing") {
            setChkThing(value);
            ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [chkMessage, value, chkProduct, chkGateway]);
        } else if (e.target.name === "chkProduct") {
            setChkProduct(value);
            ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [chkMessage, chkThing, value, chkGateway]);
        } else if (e.target.name === "chkGateway") {
            setChkGateway(value);
            ShareService.requestMessageFormat("HTTP", setTxtMessageFormat, [chkMessage, chkThing, chkProduct, value]);
        }
    };

    const handleValue = (e) => {
        const value = e.target.value;

        if (e.target.name == "txtHost") {
            setHost(value);
            setUrl(connection === "http" ? "http://" + value : "https://" + value);
            // setUrl(value);
        } else if (e.target.name == "txtHeaders") {
            setHeaders(value);
        } else if (e.target.name == "selMethod") {
            setMethod(value);
        } else if (e.target.name == "cboGrouped") {
            setGrouped(e.target.checked);
        }
    };

    const closeDialog = (answer) => {
        if (!answer) {
            props.goFirstPage();
        }
    };

    const handleFiles = (certAuth, cert, privKey) => {
        setFiles([certAuth, cert, privKey]);
    };

    return (
        <>
            <Paper elevation={6} spacing={2}>
                {props.on && <ConfirmDialog callback={closeDialog}></ConfirmDialog>}

                <Grid container padding={2}>
                    <Grid item xs={6} sm={6}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{ backgroundColor: "#9CC96B" }} onClick={() => handleSave()}>
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Connection</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={2} sm={2}>
                                <Typography>Url</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                                        <ButtonGroup>
                                            <Button
                                                value="left"
                                                onClick={() => {
                                                    setConnection("http");
                                                    setUrl("http://" + host);
                                                }}
                                                style={{
                                                    textTransform: "none",
                                                    backgroundColor: connection === "http" ? "#5694B5" : grey[400],
                                                    color: "white",
                                                    // cursor: cursor,
                                                }}
                                            >
                                                Http
                                            </Button>
                                            <Button
                                                value="center"
                                                onClick={() => {
                                                    setConnection("https");
                                                    setUrl("https://" + host);
                                                }}
                                                style={{
                                                    textTransform: "none",
                                                    backgroundColor: connection === "https" ? "#5694B5" : grey[400],
                                                    color: "white",
                                                    // cursor: cursor,
                                                }}
                                            >
                                                Https
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                    <TextField
                                        required
                                        name="txtHost"
                                        fullWidth
                                        margin="dense"
                                        size="small"
                                        value={host}
                                        onChange={(e) => handleValue(e)}
                                        error={errors.host ? true : false}
                                        sx={CustomStyle.RemoveLabel}
                                    />
                                </Box>
                                {connection === "https" && (
                                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <UploadButtons callback={handleFiles} filesOnCloud={filesOnCloud} />
                                    </Box>
                                )}

                                {connection === "https" && errors.https && (
                                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <Alert severity="error">
                                            Please ensure that both the CA, certificate, and private key are all set before proceeding.
                                        </Alert>
                                    </Box>
                                )}
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Headers</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <TextField
                                    required
                                    name="txtHeaders"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    multiline
                                    rows={4}
                                    value={headers}
                                    onChange={(e) => handleValue(e)}
                                    error={errors.headers ? true : false}
                                    sx={CustomStyle.RemoveLabel}
                                    helperText={
                                        <>
                                            header1=headervalue1
                                            <br />
                                            header2=headervalue2
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Method</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <FormControl sx={{ m: 1, minWidth: 150 }}>
                                    <Select
                                        // labelId="demo-simple-select-label"
                                        // id="demo-simple-select"
                                        name="selMethod"
                                        value={method}
                                        onChange={(e) => handleValue(e)}
                                        sx={CustomStyle.RemoveLabel}
                                        // defaultValue={'POST'}
                                    >
                                        <MenuItem value={"GET"}>GET</MenuItem>
                                        <MenuItem value={"POST"}>POST</MenuItem>
                                        <MenuItem value={"PUT"}>PUT</MenuItem>
                                        <MenuItem value={"DELETE"}>DELETE</MenuItem>
                                        <MenuItem value={"PATCH"}>PATCH</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Typography>Group measurements</Typography>
                            </Grid>
                            <Grid item xs={10} sm={10}>
                                <Checkbox name="cboGrouped" checked={grouped} onChange={(e) => handleValue(e)} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                        <Typography>Message format</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} sm={3}>
                                    <FormControlLabel control={<Checkbox name="chkMeasurement" defaultChecked disabled />} label="Measurement" />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox name="chkMessage" checked={chkMessage} onClick={(e) => handleMsgFormat(e)} />}
                                        label="Message"
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox name="chkThing" checked={chkThing} onClick={(e) => handleMsgFormat(e)} />}
                                        label="Thing"
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox name="chkProduct" checked={chkProduct} onClick={(e) => handleMsgFormat(e)} />}
                                        label="Product"
                                    />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                    <FormControlLabel
                                        control={<Checkbox name="chkGateway" checked={chkGateway} onClick={(e) => handleMsgFormat(e)} />}
                                        label="Gateway"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    name="txtMessageFormat"
                                    fullWidth
                                    margin="dense"
                                    multiline
                                    rows={15}
                                    value={txtMessageFormat}
                                    sx={CustomStyle.RemoveLabel}
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        </>
    );
}
