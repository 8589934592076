import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ApiService from "Services/apiService";
import _ from "underscore";
import { BootstrapDialog, BootstrapDialogTitle } from '../../../components/ui/BootstrapDialog'
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

export default function Confirm({ callback, selectionModel, open, setOpen }) {
    const { t, i18n } = useTranslation();
    const [disableButtons, setDisableButtons] = useState(false);

    useEffect(() => {
        setDisableButtons(false);
    }, []);

    const closeModal = (e) => {
        // console.log("closeMode....")
        setOpen(false);
        callback();
    };

    const handleDelete = () => {
        if (selectionModel.length > 0) {
            setDisableButtons(true);

            let allWaitRequest = deleteThings(selectionModel);

            Promise.all(allWaitRequest)
                .then((result) => {
                    // console.log("result...")
                    // console.log(result);
                    closeModal();
                })
                .catch((result) => {
                    _.each(result, (r) => {
                        console.error(r);
                    });
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                maxWidth="sm"
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {t("de.delete_title")}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {selectionModel.length == 0 && t('de.delete_select')}
                    </Typography>
                    <Typography gutterBottom>
                        {selectionModel.length > 0 && `${t("de.delete_confirm")} ${selectionModel.length} ${t("de.delete_confirm_things")} ?`}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    {selectionModel.length > 0 && (
                        <Button variant="contained" color="primary" style={{ textTransform: "unset" }} onClick={handleDelete} disabled={disableButtons}>
                            {t("btn.yes")}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "unset", marginLeft: "10px" }}
                        onClick={closeModal}
                        disabled={disableButtons}
                    >
                        {t("btn.no")}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}


function deleteThings(thingIds) {
    let allPromise = [];

    _.each(thingIds, (thingId) => {
        let requestOptions = {
            url: "/api/things/" + thingId + "?force=true",
        };

        let p = ApiService.deleteMultipleData(requestOptions);

        allPromise.push(p);
    });

    return allPromise;
}
