import React, { useState, useEffect, useRef } from "react";
import { Text, Image as KonvaImage, Rect, Group } from "react-konva";
import IslandService from "./IslandService";
import ImgBlue from "Assets/icons/group-blue.png";
import ImgGreen from "Assets/icons/group-green.png";
import ImgOrange from "Assets/icons/group-orange.png";
import ImgRed from "Assets/icons/group-red.png";
import ImgYellow from "Assets/icons/group-yellow.png";
import useImage from "use-image";

export default function IslandKonvaImage({ positions, callback }) {
    const [state, setState] = useState({
        isDragging: false,
        x: 0,
        y: 0,
    });

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    const [worstColor, setWorstColor] = useState("BLUE"); // Default worstColor to 'BLUE'

    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(180);

    const tooltipRef = useRef();
    const imageRef = useRef();

    // Load all images
    const [imgBlue] = useImage(ImgBlue);
    const [imgGreen] = useImage(ImgGreen);
    const [imgOrange] = useImage(ImgOrange);
    const [imgRed] = useImage(ImgRed);
    const [imgYellow] = useImage(ImgYellow);

    // Map worstColor to corresponding image
    const imageMap = {
        BLUE: imgBlue,
        GREEN: imgGreen,
        ORANGE: imgOrange,
        RED: imgRed,
        YELLOW: imgYellow,
    };

    useEffect(() => {
        if (positions) {
            const position = positions.position;

            if (position) {
                setState({
                    isDragging: false,
                    x: position.x,
                    y: position.y,
                });

                setTooltipX(position.x);
                setTooltipY(position.y);
            }

            IslandService.requestThing(positions.thingId).then((ctx) => {
                if (ctx) {
                    let arrText = [];
                    let height = 0;

                    ctx.forEach((e) => {
                        const capitalizedName = e.name.charAt(0).toUpperCase() + e.name.slice(1);
                        arrText.push([`${capitalizedName}`, `${e.measurement} ${e.unit}`]);
                        height += 17;
                    });

                    setTooltipHeight(height);
                    setTooltipText(arrText);
                    setWorstColor(ctx.worstColor || "BLUE"); // Set the worstColor or default to 'BLUE'
                }
            });
        }
    }, [positions]);

    useEffect(() => {
        // Update Konva image when worstColor changes
        const imageNode = imageRef.current;
        if (imageNode) {
            imageNode.image(imageMap[worstColor]);
            imageNode.getLayer().batchDraw(); // Redraw the layer to reflect the image change
        }
    }, [worstColor, imageMap]);

    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.zIndex(100); // Set a high zIndex value to bring it to the top
        }
    }, [tooltipVisible]);

    const handleClick = () => {
        callback(positions.name);
    };

    return (
        <>
            <KonvaImage
                ref={imageRef} // Reference to the Konva image node
                image={imageMap[worstColor]} // Default image based on worstColor
                x={state.x}
                y={state.y}
                width={50}
                height={50}
                draggable={false}
                onDragStart={() => {
                    setState({
                        isDragging: true,
                    });
                }}
                onDragEnd={(e) => {
                    setState({
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    });

                    setTooltipX(e.target.x());
                    setTooltipY(e.target.y());
                }}
                onMouseEnter={() => {
                    setTooltipVisible(true);
                }}
                onMouseOut={() => {
                    setTooltipVisible(false);
                }}
                onClick={handleClick}
            />
            <Group x={tooltipX - 23} y={tooltipY + 60}>
                <Rect fill="black" opacity={0.75} cornerRadius={5} width={100} height={25} />
                <Text text={positions.name} fill="white" padding={5} fontSize={14} align="center" drawBorder={true} width={100} />
            </Group>

            {tooltipVisible && (
                <Group ref={tooltipRef} x={tooltipX + 60} y={tooltipY}>
                    <Rect fill="black" opacity={0.75} cornerRadius={5} width={tooltipWidth} height={tooltipHeight} />

                    {tooltipText &&
                        tooltipText.map((t, i) => {
                            return (
                                <Group key={i}>
                                    {/* Left-aligned Text */}
                                    <Text
                                        text={t[0]}
                                        fill="white"
                                        padding={5}
                                        fontSize={14}
                                        align="left"
                                        y={i * 16} // Adjust the y position based on the index
                                        x={5} // Small padding from the left edge
                                        drawBorder={true}
                                    />
                                    {/* Right-aligned Text */}
                                    <Text
                                        text={t[1]}
                                        fill="white"
                                        padding={5}
                                        fontSize={14}
                                        align="right"
                                        y={i * 16} // Adjust the y position based on the index
                                        width={tooltipWidth}
                                        drawBorder={true}
                                    />
                                </Group>
                            );
                        })}
                </Group>
            )}
        </>
    );
}
