import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { AppId } from "../../../components/applications/ApplicationData";
import GatewayService from "./GatewayService";
import validator from "validator";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CustomButton } from "Components/ui/CustomButton";
import CreateGatewayDialog from "./CreateGatewayDialog";
import UserService from "../../../services/userService";
import moment from "moment";
import { frFR } from "@mui/x-data-grid/locales";
import { LicenseInfo } from "@mui/x-license-pro";
import { GatewayColumns } from "./GatewayColumns";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

export default function GatewayMain() {
    const { t, i18n } = useTranslation();

    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [sort, setSort] = useState([{ field: "company", sort: "asc" }]);

    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState();

    const appId = AppId.DEVICE_EXPLORER;

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            // console.log(u)

            if (u.authorities == "SUPER_ADMIN") {
                GatewayService.requestGateways(paginationModel.page, paginationModel.pageSize, setRows, sort, appId, true, "PTH", setRowCount);
            } else {
                GatewayService.requestGateways(paginationModel.page, paginationModel.pageSize, setRows, sort, appId, false, u.company, setRowCount);
            }
        });
    }, [appId, paginationModel, reload, sort]);

    const handleName = (p, e) => {
        if (p.field === "name") {
            if (validator.isEmpty(e.target.value)) {
                return;
            }
            GatewayService.updateGatewayName(p.id, e.target.value);
        }
    };

    const handleClick = (event, cellValues) => {
        GatewayService.updateGatewayStatus(cellValues.id).then(() => {
            setReload(moment());
        });
    };

    const handleSortModelChange = (param) => {
        setSort(param);
    };

    const columns = GatewayColumns(t, handleClick);

    return (
        <Box sx={{ flexGrow: 1, mt: 8, p: 2 }}>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <CustomButton name={t("btn.add")} callback={() => setOpen(true)} />
            </Grid>
            <Grid item xs={12} style={{ height: 700 }}>
                <DataGridPro
                    rows={rows}
                    rowCount={rowCount}
                    columns={columns}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pagination={true}
                    paginationMode="server"
                    sortingMode="server"
                    pageSizeOptions={[15, 50, 100]}
                    onSortModelChange={handleSortModelChange}
                    checkboxSelection={false}
                    density="compact"
                    sx={{
                        "& .MuiTablePagination-selectLabel": {
                            marginTop: 2,
                        },
                        "& .MuiTablePagination-displayedRows": {
                            marginTop: 2,
                        },
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                    }}
                    hideFooterSelectedRowCount
                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                    onCellEditStop={handleName}
                />
            </Grid>
            <CreateGatewayDialog open={open} setOpen={setOpen} setReload={setReload} />
        </Box>
    );
}
