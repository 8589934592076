export function BoilerColumns(t) {
    return [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "name",
            headerName: t("col.name"),
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "measurement",
            headerName: "Measurement",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
        {
            field: "value",
            headerName: "Value",
            flex: 1,
            editable: false,
            sortable: true,
            filterable: false,
        },
    ];
}
